<template>
    <div>
        <Wapheader />
        <div><img src="../../assets/images/ptt.png" alt=""></div>
        <div class="text_1">
            <div><span style="margin-right: 3rem;">纯电专属</span>全面定义</div>
            <vue-typed-js class="desc" :strings="['全球电动车市场智能化<br/>解决方案']" :loop="false" :startDelay="300"
                :typeSpeed="100" :backSpeed="50">
                <span class="typing text"></span>
            </vue-typed-js>
        </div>
        <div><img src="../../assets/images/cnw/banner1.png" alt=""></div>
        <div class="text_2">
            ionex车能网，是光阳集团自主研发的<span>智能安全出行生态系统</span>，其核心特征是<span>硬件层、系统层以及生态层</span>均进行深度开发，是一个从硬件到芯片、云端再到应用的全生态架构。
        </div>
        <div class="text_3">
            同时围绕电动摩托车为核心的驾驶体验，ionex拥有强大的<span>自我迭代能力</span>，为全面开创两轮智能出行的新体验打下坚实的基础。
        </div>
        <div><img src="../../assets/images/cnw/banner-img.png" alt=""></div>
        <div class="text_4">
            <div class="text_5">
                摩托车领域<br>
                领创能源智慧共享体系
            </div>
            <div class="text_6">
                英捷鑫智能共享云端，拥有与两轮出行相匹配<br>的，广泛的能源补给布设方式。<br>
                在家可以灵活车电分离智能充电、也可以随处在<br>最近的商家进行快充、换电，依托云技术，<br>
                搭建共享可充+可租、可换、可升级的能源补给<br>体系，为车主提供全场景化的加电服务。
            </div>
            <img src="../../assets/images/cnw/banner-img2.png" alt="" class="banner-img2">
        </div>
        <div><img src="../../assets/images/cnw/banner-img3.png" alt="" class="banner-img3"></div>
        <div class="text_7">
            <div class="text_8">
                随心所换 多重保障
            </div>
            <div class="swiper mySwiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="swiper-item">
                            <div class="text_9">
                                <div class="text_10">
                                    高能动力锂电池BMS系统
                                </div>
                                <div class="text_11">
                                    搭建光阳独家开发的高能动力锂电池BMS系统，<br>
                                    使其独具高能量密度、高充放电倍率的双高性能。
                                </div>
                            </div>
                            <div><img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/01.gif" alt=""></div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="swiper-item">
                            <div class="text_9">
                                <div class="text_10">
                                    智能换电系统
                                </div>
                                <div class="text_11">
                                    全方位布局Ionex 3.0 以提供多元的能源补充方式、<br>
                                    建设电池交换网路、让消费者安心拥有电动机车。
                                </div>
                            </div>
                            <div><img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/02.gif" alt=""></div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="swiper-item">
                            <div class="text_9">
                                <div class="text_10">
                                    智能车控系统
                                </div>
                                <div class="text_11">
                                    集结实用工具、便捷操控与车辆讯息，<br>
                                    生活有了 KYMCO App 更加轻松便利。
                                </div>
                            </div>
                            <div><img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/03.gif" alt=""></div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="swiper-item">
                            <div class="text_9">
                                <div class="text_10">
                                    云服务平台
                                </div>
                                <div class="text_11">
                                    为车主提供稳定可靠、安全可信<br>
                                    可持续创新的云服务
                                </div>
                            </div>
                            <div><img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/04.gif" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
            </div>
        </div>
        <Wapfooter />
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    mounted() {
        this.$nextTick(() => {
            new Swiper('.mySwiper', {
                loop: false,
                slidesPerView: 1.5,
                autoplay: true,//可选选项，自动滑动
                centeredSlides: true,
                spaceBetween: 40,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            })
        })
    }
}
</script>
<style>
.typed-element .typed-cursor {
   opacity: 0;
}

.typing.text:after {
    content: "";
    display: inline-block;
    width: 4px;
    height: 70px;
    margin-left: 6px;
    border-radius: 4px;
    background: rgba(0, 150, 255, 1);
    vertical-align: top;
    animation: typedjsBlink 0.7s infinite;
}
</style>
<style lang="less" scoped>
/deep/ swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: 105px;
}

//修改swiper指示点大小
/deep/ .swiper-pagination-bullet {
    height: 20px;
    width: 20px;
}

//修改指示点未选中颜色
/deep/ .swiper-pagination-bullet {
    background: #A0A0A0;
}

//修改指示点选中颜色
/deep/ .swiper-pagination-bullet-active {

    background: #FFFFFF;
}

.mySwiper {
    margin-top: 75px;
    padding-bottom: 225px;
}

.text_9 {
    height: 280px;
    padding: 60px 35px;
    background: #fff;
    border-radius: 30px 30px 0 0;

    .text_10 {
        font-size: 32px;
        line-height: 40px;
        font-family: 'Albbm';
    }

    .text_11 {
        font-family: 'Albbr';
        font-size: 16px;
        margin-top: 30px;
    }
}

.swiper-item {
    border-radius: 30px;
    overflow: hidden;
    width: 480px;
}

.text_7 {
    background: #E1E1E1;

    .text_8 {
        padding-top: 145px;
        font-size: 48px;
        line-height: 100px;
        font-family: 'Albbr';
        text-align: center;
    }
}

.banner-img3 {
    height: 700px;
}

.banner-img2 {
    margin-top: 90px;
}

.text_4 {
    padding: 90px 80px;

    .text_5 {
        font-size: 48px;
        line-height: 64px;
        font-family: 'Albbr';
    }

    .text_6 {
        margin-top: 45px;
        font-size: 28px;
        line-height: 45px;
        font-family: 'Albbr';
    }
}

.text_3 {
    margin-top: -10px;
    padding: 0 80px;
    font-size: 28px;
    line-height: 45px;
    margin-bottom: 105px;
    font-family: 'Albbr';

    span {
        font-family: 'Albb';
    }
}

.text_2 {
    padding: 50px 80px;
    font-size: 28px;
    line-height: 45px;
    font-family: 'Albbr';

    span {
        font-family: 'Albb';
    }
}

/deep/ .typed-cursor {
    color: #26A7FF;
}

.text_1 {
    padding: 90px 80px;
    font-size: 54px;

    span {
        
    }
}
</style>